<ion-app class="ion-app">
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" class="sidemenu" side="end">

      <ion-header class="ion-header">
        <ion-toolbar class="ion-toolbar">
          <ion-title class="menu-content">{{ 'sidemenu.menu' | translate }}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-content" scrollX="true" scrollY="true">

          <ion-list lines="full">

            <lang-selector [showLabel]="true"></lang-selector>

            <ion-menu-toggle autoHide="false">
              <ion-item [routerDirection]="'forward'" [routerLink]="[p.url]" *ngFor="let p of appPages">
                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                <ion-label class="ion-label menu-content">
                  {{p.title}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-item (click)="toggleSubmenu()">
              <ion-icon name="arrow-dropright" slot="start" *ngIf="!showSubmenu"></ion-icon>
              <ion-icon name="arrow-dropdown" slot="start" *ngIf="showSubmenu"></ion-icon>
              <ion-label class="ion-label menu-content">
                {{ 'sidemenu.services' | translate }}
              </ion-label>
              <ion-spinner name="crescent" *ngIf="loadingServices"></ion-spinner>
              <ion-badge color="light" slot="end">{{ servicesPages.length }}</ion-badge>
            </ion-item>
            <ion-item-group  class="dropdown-menu" [ngClass]="showSubmenu ? 'visible' : 'hidden'">
              <ion-menu-toggle autoHide="false">
                <ion-item *ngFor="let service of servicesPages" lines="none" [routerLink]="['/service-details', service.id]" routerDirection="root" >
                  <ion-icon name="ios-return-right" slot="start"></ion-icon>
                  <ion-label class="ion-label menu-content">
                    {{ service.name }}
                  </ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-item-group>
            <ion-menu-toggle autoHide="false">
              <ion-item [routerDirection]="'forward'" [routerLink]="[p.url]" *ngFor="let p of appPages2" [ngClass]="p.class">
                <ion-icon slot="start" [name]="p.icon"></ion-icon>
                <ion-label class="ion-label menu-content">
                  {{p.title | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle autoHide="false" *ngIf="externalTopLink">
              <ion-item [href]="externalTopLink.link" target="_blank">
                <ion-icon src='data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="768" viewBox="0 0 1024 768"><path d="M640 768H128V257.90599999999995L256 256V128H0v768h768V576H640V768zM384 128l128 128L320 448l128 128 192-192 128 128V128H384z"></path></svg>'></ion-icon>
                <ion-label class="ion-label menu-content">
                  {{externalTopLink.title}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <ion-list *ngIf="user">
            <ion-list-header class="menu-content">
              {{ 'sidemenu.account' | translate }}
            </ion-list-header>
            <ion-item>
              <ion-icon slot="start" name="contact"></ion-icon>
              <ion-label class="ion-label menu-content">
                {{ user.username }}
              </ion-label>
            </ion-item>
            <ion-menu-toggle autoHide="false">
              <ion-item button (click)="logout()" detail="false">
                <ion-icon slot="start" name="log-out"></ion-icon>
                <ion-label class="ion-label menu-content">
                  {{ 'sidemenu.logout' | translate }}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>

          <div *ngIf="collectiveConfig" class="logos-menu">
            <ion-list *ngIf="collectiveConfig.logoesr" class="logo-esr" lines="none">
              <ion-item class="logo-img">
                <img src="{{ collectiveConfig.logoesr }}">
              </ion-item>
            </ion-list>

            <ion-list *ngIf="collectiveConfig.logoProvider" class="logo-brand-name" lines="none">
              <ion-item class="logo-text menu-content">
                <span>{{ 'sidemenu.provided' | translate }}</span>
              </ion-item>
              <ion-item class="logo-img">
                <img src="{{ collectiveConfig.logoProvider }}">
              </ion-item>
              
            </ion-list>
          </div>
      </ion-content>
      
    </ion-menu>
    <ion-router-outlet id="main-content" animated=true></ion-router-outlet>
    
  </ion-split-pane>
  <whatsapp></whatsapp>

</ion-app>
