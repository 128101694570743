import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'whatsapp',
  templateUrl: './whatsapp.component.html'
})
export class WhatsappComponent {
  @ViewChild('whatsapp', {static: true}) whatsappElement: ElementRef;

  environmentVariable:  string | number | boolean;

  ngOnInit() {

    this.environmentVariable = environment.whatsapp;

    let environmentVariableText = String(this.environmentVariable); 

    if (environmentVariableText !== "false" && !environmentVariableText.startsWith("34")) {
      this.environmentVariable = "34" + this.environmentVariable;
    }
  }
}
