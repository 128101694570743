/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./whatsapp.component";
var styles_WhatsappComponent = [];
var RenderType_WhatsappComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WhatsappComponent, data: {} });
export { RenderType_WhatsappComponent as RenderType_WhatsappComponent };
function View_WhatsappComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["id", "whatsapp-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["src", "/assets/whatsapp-logo.png"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "https://wa.me/", _co.environmentVariable, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_WhatsappComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { whatsappElement: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_WhatsappComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.environmentVariable; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WhatsappComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "whatsapp", [], null, null, null, View_WhatsappComponent_0, RenderType_WhatsappComponent)), i0.ɵdid(1, 114688, null, 0, i2.WhatsappComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhatsappComponentNgFactory = i0.ɵccf("whatsapp", i2.WhatsappComponent, View_WhatsappComponent_Host_0, {}, {}, []);
export { WhatsappComponentNgFactory as WhatsappComponentNgFactory };
